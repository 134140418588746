import Splide from '@splidejs/splide';
let newsSlider = document.querySelectorAll(".news-splide")

if (newsSlider) {
	newsSlider.forEach(news => {
		var splideNews = new Splide(news, {
			type   : 'loop',
			perPage: 3,
			gap:24,
			arrows: false,
			breakpoints: {
				640: {
					perPage: 1,
				},
				992: {
                    perPage: 3,
                }
		  	}
		}).mount()
	})
}
