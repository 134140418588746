import Splide from '@splidejs/splide';
let Hero = document.querySelectorAll(".hero-splide")

if (Hero) {
	Hero.forEach(related => {
		var splideHero= new Splide(related, {
			type   : 'loop',
			perPage: 1,
			gap:0,
			speed : 1000,
			breakpoints: {
				991: {
					arrows: false
				},
		  	}
		}).mount()
	})
}