import { gsap } from "gsap";
    
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { EaselPlugin } from "gsap/EaselPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";


gsap.registerPlugin(Flip,ScrollTrigger,Observer,ScrollToPlugin,Draggable,EaselPlugin,PixiPlugin,TextPlugin);

gsap.utils.toArray('.banner').forEach((element) => {
	let text = $(element).find("h2 span")
	let button = $(element).find(".button-cta")
	gsap.to(
		element, {
			duration: 0.5,
			width:'100%',
			scrollTrigger: {
				markers: false,
				trigger: element,
				start: "top bottom",
				end: "top 30%",
				scrub: 1,
				pinSpacing: false
			}
		}
	)
	gsap.to(
		text, {
            duration: 0.5,
            y: 0,
            opacity: 1,
            stagger: 0.1,
            scrollTrigger: {
                markers: false,
                trigger: element,
                start: "top bottom",
                end: "top 20%",
                scrub: 1,
                pinSpacing: false
            }
        }
	)
	gsap.to(
		button, {
            duration: 0.5,
            y: 0,
            opacity: 1,
            stagger: 0.3,
            scrollTrigger: {
                markers: false,
                trigger: element,
                start: "top bottom",
                end: "top 20%",
                scrub: 1,
                pinSpacing: false
            }
        }
	)
})