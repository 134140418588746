import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray('.text-reveal').forEach((element) => {
	gsap.to(
		element.querySelector('span.to-translate'),{
		y:0,
		duration:1,
		scrollTrigger: {
			markers: true,
			trigger: element,
			start: "50% 50%",
			end: "top 20%",
			pin: false,
			scrub: 1,
			pinSpacing: false
		}
	})
})