let MenuToggler = document.querySelector('.menu-toggler');
let MenuCLoser = document.querySelectorAll('.menu-closer');
let Menu = document.querySelector('.main-nav');
let Categories = document.querySelectorAll(".button-category");
let Menus = document.querySelectorAll('.product-menu')
let submenuButtons = document.querySelectorAll('.has-submenu')
let submenus = document.querySelectorAll('.secondary-nav-inner')
let insideNav = document.querySelector('.nav-all')
let header = document.querySelector("header")
let BackButton = document.querySelectorAll('.back-menu');
let BackButtonSecondary = document.querySelectorAll('.back-menu-secondary');

MenuToggler.onclick = function() {
	Menu.classList.add('is-open');
}

MenuCLoser.forEach(function(el) {
	el.onclick = function() {
		Menu.classList.remove('is-open');
		submenus.forEach((el) => {
			el.classList.remove("active")
			el.classList.remove("overflow-active")
		})
		Menus.forEach((el) => {
			el.classList.remove("active")
			el.classList.remove("active-mobile")
		})
	}
	
})
Categories.forEach(function(el) {
	el.onclick = function() {
		Categories.forEach(function(element) {
			element.classList.remove("active")
		})
		this.classList.toggle('active')
		
		
		Menus.forEach(function(element) {
			element.classList.remove('active')
			element.classList.remove('active-mobile')
		})
		let targetData = this.getAttribute("data-rel")
		let target = document.getElementById(targetData)
		target.classList.add('active')
		target.classList.add('active-mobile')
	}
})

document.addEventListener('click', event => {
	const isClickedInsideDiv = event.composedPath().includes(header)

	if (isClickedInsideDiv) {
		
	} else {
		//click outside
		submenus.forEach(function(element) {
			element.classList.remove('active')
		})
		submenuButtons.forEach(function(el) {
			el.classList.remove('active')
			el.classList.remove('opened')
		})
	} 
})


submenuButtons.forEach(function(el) {
	let targetData = el.getAttribute("data-rel")
	let target = document.getElementById(targetData)
	submenus.forEach(function(element) {
		element.classList.remove('active')
	})
	el.onclick = function() {
		if (el.classList.contains('opened')) {
			el.classList.remove('opened')
			target.classList.remove('overflow-active')
			window.setTimeout(function() {
				target.classList.remove('active')
			},0)
		} else {
			el.classList.add('opened')
			target.classList.add('active')
			window.setTimeout(function() {
				target.classList.add('overflow-active')
			},300)
		}
	}
	
})

BackButton.forEach((el)=> {
	el.onclick = function() {
		submenus.forEach((el) => {
			el.classList.remove("active")
			el.classList.remove("overflow-active")
		})
	}
})

BackButtonSecondary.forEach((el)=> {
	el.onclick = function() {
		Menus.forEach((el) => {
			el.classList.remove("active")
			el.classList.remove("active-mobile")
		})
	}
})

