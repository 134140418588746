import { gsap } from "gsap";
    
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { EaselPlugin } from "gsap/EaselPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";


gsap.registerPlugin(Flip,ScrollTrigger,Observer,ScrollToPlugin,Draggable,EaselPlugin,PixiPlugin,TextPlugin);

gsap.utils.toArray('.card-feature').forEach((element) => {
	let img = $(element).find('img')
	gsap.to(
		img, {
			duration: 1,
			scale:1.2,
			scrollTrigger: {
				markers: false,
				trigger: element,
				start: "top bottom",
				end: "100%",
				scrub: 1,
				pinSpacing: false
			}
		}
	)
})