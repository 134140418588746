import Splide from '@splidejs/splide';
let Related = document.querySelectorAll(".related-splide")

if (Related) {
	Related.forEach(related => {
		var splideRelated = new Splide(related, {
			type   : 'loop',
			perPage: 4,
			gap:24,
			arrows: false,
			breakpoints: {
				640: {
					perPage: 1,
				},
				992: {
                    perPage: 3,
                }
		  	}
		}).mount()
	})
}
