let filters = document.querySelectorAll(".menu-item")
let subs = document.querySelectorAll(".filters-sub ul")

filters.forEach(filter => {
    filter.addEventListener("click", () => {
        let targetData = filter.getAttribute("data-rel")
        let target = document.getElementById(targetData)
		subs.forEach(sub => {
			sub.classList.remove("active")
            filter.classList.remove("active")
		})
		filters.forEach(filter => {
			filter.classList.remove("active")
		})
		filter.classList.add("active")
        target.classList.add("active")
    })
})

subs.forEach(sub => {
	let subs = sub.querySelectorAll('li label');
	let buttons = sub.querySelectorAll('li button');
	subs.forEach(button => {
		button.addEventListener("click", () => {
            button.classList.toggle("active")
        })
	})
	buttons.forEach(button => {
		button.addEventListener("click", () => {
            button.classList.toggle("active")
        })
	})
})